<template>
  <b-container>
    <b-row class="mt-2 pr-2 pl-2">
      <b-col md="5">
        <div v-if="info.event.url == 'appointment'">
          <div v-if="$store.getters['Auth/checkPermission']('appointment_statusedit')">
            <v-select transition="" v-model="statusId"
                      :clearable="false"
                      :reduce="s => s.id" label="name"
                      :options="appStatusList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </div>
          <div v-else>
            <v-select transition="" v-model="statusId"
                      :clearable="false"
                      :reduce="s => s.id" label="name"
                      :options="appStatusList"
                      :disabled="true">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </div>
        </div>
      </b-col>
      <b-col md="7" class="text-right">
        <b-button-group>
          <b-button v-if="info.event.url == 'appointment' && $store.getters['Auth/checkPermission']('appointment_edit')"
                    @click="openFastEditAppointmentPage(info.event.id)" variant="outline-primary">
            {{$t("FastEdit")}}
          </b-button>
          <b-dropdown v-if="info.event.url == 'appointment'" variant="outline-primary" right>
            <template #button-content>
              {{$t("Actions")}}
            </template>
            <div v-if="info.event.url == 'appointment'">
              <router-link target="_blank" class="dropdown-item"
                           el="a"
                           :to="{ name: 'appointment.edit', params: { appointmentId: info.event.id } }"
                           style="cursor:pointer;">
                <font-awesome-icon icon="fa-light fa-pen-to-square" />&nbsp;{{$t("Edit")}}
              </router-link>
              <b-dropdown-item v-if="$store.getters['Auth/checkPermission']('customer_details')"
                               @click="openCustomerSummaryTab(info.event.extendedProps.customerId,'customerSummary.appointments')">
                <font-awesome-icon icon="fa-light fa-user" />&nbsp;{{$t("CustomerPage")}}
              </b-dropdown-item>
              <b-dropdown-item v-if="!info.event.extendedProps.hasBillForAppointment && $store.getters['Auth/checkPermission']('bill_new')"
                               @click="openBillNewPageForAppointment('bill.newFromCustomerSummary',info.event.id,null, info.event.extendedProps.customerId)">
                <font-awesome-icon icon="fa-light fa-money-bills" />&nbsp;{{$t("CreatePayment")}}
              </b-dropdown-item>
              <b-dropdown-item @click="deleteAppointment(info.event.id,'/Appointment/Delete')">
                <font-awesome-icon icon="fa-light fa-trash-can" class="text-danger" />&nbsp;<span class="text-danger">{{$t("Delete")}}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-button-group>
      </b-col>
    </b-row>

    <hr class="mt-2 mb-2" />

    <b-row v-if="info.event.extendedProps.customerName != null">
      <b-col style="float:left" class="label" md="6">{{$t("NameSurname")}}: </b-col>
      <b-col style="float:right" class="data" md="6">
        <router-link v-if="$store.getters['Auth/checkPermission']('customer_details')"
                     class="data text-primary"
                     target="_blank"
                     :to="{ name: 'customerSummary.details', params: {customerId:info.event.extendedProps.customerId} }">
          {{info.event.extendedProps.customerName}}
        </router-link>
        <span v-else class="data">{{info.event.extendedProps.customerName}}</span>
      </b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.customerCardNo != null">
      <b-col style="float:left" class="label" md="6">{{$t("CardNo")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.customerCardNo}}</b-col>
    </b-row>

    <b-row>
      <b-col style="float:left" class="label" md="6">{{$t("Time")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{startTime}} - {{endTime}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.customerPhone && info.event.extendedProps.customerPhone.length > 0">
      <b-col style="float:left" class="label" md="6">{{$t("Phone")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.customerPhone}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.customerNationalNumber && info.event.extendedProps.customerNationalNumber.length > 0">
      <b-col style="float:left" class="label" md="6">{{$t("NationalNumber")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.customerNationalNumber}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.customerDateOfBirth && info.event.extendedProps.customerDateOfBirth.length > 0">
      <b-col style="float:left" class="label" md="6">{{$t("DateOfBirth")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.customerDateOfBirth}} &nbsp; ({{info.event.extendedProps.customerAge}})</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.services != null" md="12">
      <b-col style="float:left" class="label" md="4">{{$t("Service")}}:</b-col>
      <b-col style="float:right" class="data" md="8">{{info.event.extendedProps.services}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.staffName != null" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("Staff")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.staffName}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.branchRoomName != null" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("Room")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.branchRoomName}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.typeName != null" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("AppointmentType")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.typeName}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.notes && info.event.extendedProps.notes.length > 0" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("Note")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.notes}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.created && info.event.extendedProps.created.length > 0" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("Created")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.created}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.createdBy && info.event.extendedProps.createdBy.length > 0" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("CreatedBy")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.createdBy}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.modified && info.event.extendedProps.modified.length > 0" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("Modified")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.modified}}</b-col>
    </b-row>

    <b-row v-if="info.event.extendedProps.modifiedBy && info.event.extendedProps.modifiedBy.length > 0" md="12">
      <b-col style="float:left" class="label" md="6">{{$t("ModifiedBy")}}:</b-col>
      <b-col style="float:right" class="data" md="6">{{info.event.extendedProps.modifiedBy}}</b-col>
    </b-row>

    <b-card-footer class="text-center">
      <b-button @click="closeModal()" variant="info">{{$t('Close')}}</b-button>
    </b-card-footer>
  </b-container>
</template>
<script>
  import appointmentService from '../../services/appointment'
  import commonService from '../../services/common'

  import { deleteConfirmation } from '../../helpers/swalConfirmations'
  import { refreshTimeGridCalendar, refreshTimeLineCalendar, refreshGeneralCalendar } from '../../helpers/dataRefresher'

  import FastEditAppointmentModal from '../appointment/FastEditAppointmentModal'

  export default {
    name: 'AppointmentDetails',
    props: {
      info: Object
    },
    data() {
      return {
        statusId: '',
        appStatusList: [],
      }
    },
    methods: {
      closeModal() {
        this.$modal.hide('vue-modal');
      },
      changeStatus(statusId) {
        appointmentService.changeStatus(this.info.event.id, statusId)
          .then(response => {
            if (response) {
              this.$toastr.success(this.$t("Success"));
              if (this.$router.currentRoute.name == 'calendar.general') {
                refreshGeneralCalendar()
              }
              else if (this.$router.currentRoute.name == 'calendar.staffClassic' || this.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeGridCalendar()
              }
              else if (this.$router.currentRoute.name == 'calendar.staffGrid' || this.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeLineCalendar()
              }
            } else {
              this.$toastr.error(this.$t('Error'));
            }
          });
      },
      openAppointmentEditPage(id) {
        this.closeModal();
        this.$router.push({ name: 'appointment.edit', params: { appointmentId: id } });
      },
      openCustomerSummaryTab(customerId, routeName) {
        this.closeModal();
        this.$router.push({ name: routeName, params: { customerId: customerId } });
      },
      openBillNewPageForAppointment(name, appointmentId, sketchId, customerId) {
        this.closeModal();
        this.$router.push({ name: name, params: { appointmentId: appointmentId, customerId: customerId } });
      },
      deleteAppointment(id, route) {
        this.closeModal();
        deleteConfirmation(id, route);
      },
      getAppointmentStatusList() {
        commonService.getAppointmentStatusList().then((response) => {
          if (response) {
            this.appStatusList = response;
            this.statusId = this.info.event.extendedProps.statusId;
          }
        })
      },
      openFastEditAppointmentPage(appointmentId) {
        let $this = this;

        this.closeModal();

        $this.$modal.show(
          FastEditAppointmentModal,
          {
            appointmentId: appointmentId
          },
          {
            minWidth: 270,
            width: 1270,
            minHeight: 300,
            height: 650
          },
          {
            'before-close': () => {
              if (this.$router.currentRoute.name == 'calendar.general') {
                refreshGeneralCalendar()
              }
              else if (this.$router.currentRoute.name == 'calendar.staffClassic' || this.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeGridCalendar()
              }
              else if (this.$router.currentRoute.name == 'calendar.staffGrid' || this.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeLineCalendar()
              }
            }
          }
        )
      },
    },
    watch: {
      statusId: function (val, oldVal) {
        if (oldVal && val && val.length > 0) {
          this.changeStatus(val);
        }
      }
    },
    computed: {
      startTime: function () {
        return this.$moment(this.info.event.start).format('HH:mm');
      },
      endTime: function () {
        return this.$moment(this.info.event.end).format('HH:mm');
      }
    },
    mounted() {
      this.getAppointmentStatusList();
    }
  }
</script>
<style>

  .label {
    color: #48465b;
    font-weight: 600;
  }

  .data {
    text-align: right;
  }
</style>
