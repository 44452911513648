var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group row",staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t('AppointmentStatus')))]),_c('v-select',{attrs:{"transition":"","reduce":s => s.id,"label":"name","options":_vm.appointmentStatusList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoMatchingOptionsMessage'))+" ")]},proxy:true}]),model:{value:(_vm.appointmentStatusId),callback:function ($$v) {_vm.appointmentStatusId=$$v},expression:"appointmentStatusId"}})],1),_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t('BranchRoom')))]),_c('v-select',{attrs:{"transition":"","reduce":s => s.id,"label":"name","options":_vm.roomList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoMatchingOptionsMessage'))+" ")]},proxy:true}]),model:{value:(_vm.roomId),callback:function ($$v) {_vm.roomId=$$v},expression:"roomId"}})],1),(!_vm.$store.getters['Auth/currentStaff'].showOnlySelfCustomers)?_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t('Staff')))]),_c('v-select',{attrs:{"transition":"","reduce":s => s.id,"label":"name","options":_vm.staffList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoMatchingOptionsMessage'))+" ")]},proxy:true}],null,false,3412994932),model:{value:(_vm.staffId),callback:function ($$v) {_vm.staffId=$$v},expression:"staffId"}})],1):_vm._e()]),_c('FullCalendar',{ref:"generalCalendar",attrs:{"id":"generalCalendar","defaultView":_vm.defaultView,"plugins":_vm.calendarPlugins,"locale":_vm.locale,"navLinks":_vm.navlinks,"header":{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                         },"views":{
                            dayGridMonth: { buttonText: _vm.$t('Month') },
                            timeGridWeek: { buttonText: _vm.$t('Week') },
                            timeGridDay: { buttonText: _vm.$t('Day') },
                            listWeek: { buttonText: _vm.$t('List') }
                        },"buttonText":_vm.buttonText,"custom-buttons":_vm.customButtons,"defaultDate":_vm.TODAY,"slotDuration":_vm.slotDuration,"eventLimit":_vm.eventLimit,"allDaySlot":_vm.allDaySlot,"firstDay":_vm.firstDay,"weekStart":_vm.weekStart,"minTime":_vm.minTime,"maxTime":_vm.maxTime,"disableResizing":_vm.disableResizing,"eventDurationEditable":_vm.eventDurationEditable,"eventStartEditable":_vm.eventStartEditable,"selectable":_vm.selectable,"longPressDelay":_vm.longPressDelay,"selectHelper":_vm.selectHelper,"slotEventOverlap":_vm.slotEventOverlap,"handleWindowResize":_vm.handleWindowResize,"slotLabelFormat":_vm.slotLabelFormat,"eventTimeFormat":_vm.eventTimeFormat,"weekends":_vm.calendarWeekends,"events":_vm.getCalendarEvents},on:{"eventClick":_vm.handleEventClick,"eventResize":_vm.handleEventResize,"eventDrop":_vm.handleEventDrop,"eventRender":_vm.handleEventRender,"select":_vm.handleSelect,"eventMouseLeave":_vm.handleEventMouseLeave}}),_c('div',{staticClass:"calendar-tooltip d-none"}),_c('div',{attrs:{"id":"generalCalendar_refresher"},on:{"click":function($event){return _vm.refreshGeneralCalendar()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }