import axios from './index'

export default {
  getCalendarEvents(startDate, endDate, roomId, staffId, statusId, colorType) {
    let url = `/calendar/getCalendarEvents?startDate=${startDate}&endDate=${endDate}&roomId=${roomId}&staffId=${staffId}&statusId=${statusId}&colorType=${colorType}`;

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCalendarEventsForColumnCalendar(startDate, endDate, mode, statusId, colorType) {
    let url = `/calendar/getCalendarEvents_ForColumnCalendar?startDate=${startDate}&endDate=${endDate}&mode=${mode}&statusId=${statusId}&colorType=${colorType}`;

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  calendarResize(appointmentId, minuteDelta) {
    let url = `/calendar/calendar_Resize?appointmentId=${appointmentId}&minuteDelta=${minuteDelta}`;

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  calendarMove(appointmentId, dayDelta, minuteDelta) {
    let url = `/calendar/calendar_Move?appointmentId=${appointmentId}&dayDelta=${dayDelta}&minuteDelta=${minuteDelta}`;

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateAppointmentStartAndEndTime(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCalendarEventStaffList() {
    let url = '/calendar/getCalendarEvents_StaffList';

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCalendarEventRoomList() {
    let url = '/calendar/getCalendarEvents_RoomList';

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
