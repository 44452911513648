<template>
  <div>
    <div class="form-group row" style="display:flex; justify-content:center;">
      <div class="col-md-4">
        <label class="form-control-label">{{ $t('AppointmentStatus') }}</label>
        <v-select transition="" v-model="appointmentStatusId"
                  :reduce="s => s.id" label="name"
                  :options="appointmentStatusList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </div>
      <div class="col-md-4">
        <label class="form-control-label">{{ $t('BranchRoom') }}</label>
        <v-select transition="" v-model="roomId"
                  :reduce="s => s.id" label="name"
                  :options="roomList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </div>
      <div class="col-md-4" v-if="!$store.getters['Auth/currentStaff'].showOnlySelfCustomers">
        <label class="form-control-label">{{ $t('Staff') }}</label>
        <v-select transition="" v-model="staffId"
                  :reduce="s => s.id" label="name"
                  :options="staffList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </div>
    </div>
    <FullCalendar ref="generalCalendar"
                  id="generalCalendar"
                  :defaultView="defaultView"
                  :plugins="calendarPlugins"
                  :locale="locale"
                  :navLinks="navlinks"
                  :header="{
                              left: 'prev,next today',
                              center: 'title',
                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                           }"
                  :views="{
                              dayGridMonth: { buttonText: $t('Month') },
                              timeGridWeek: { buttonText: $t('Week') },
                              timeGridDay: { buttonText: $t('Day') },
                              listWeek: { buttonText: $t('List') }
                          }"
                  :buttonText="buttonText"
                  :custom-buttons="customButtons"
                  :defaultDate="TODAY"
                  :slotDuration="slotDuration"
                  :eventLimit="eventLimit"
                  :allDaySlot="allDaySlot"
                  :firstDay="firstDay"
                  :weekStart="weekStart"
                  :minTime="minTime"
                  :maxTime="maxTime"
                  :disableResizing="disableResizing"
                  :eventDurationEditable="eventDurationEditable"
                  :eventStartEditable="eventStartEditable"
                  :selectable="selectable"
                  :longPressDelay="longPressDelay"
                  :selectHelper="selectHelper"
                  :slotEventOverlap="slotEventOverlap"
                  :handleWindowResize="handleWindowResize"
                  :slotLabelFormat="slotLabelFormat"
                  :eventTimeFormat="eventTimeFormat"
                  :weekends="calendarWeekends"
                  :events="getCalendarEvents"
                  @eventClick="handleEventClick"
                  @eventResize="handleEventResize"
                  @eventDrop="handleEventDrop"
                  @eventRender="handleEventRender"
                  @select="handleSelect"
                  @eventMouseLeave="handleEventMouseLeave" />

    <div class="calendar-tooltip d-none"></div>

    <div id="generalCalendar_refresher" @click="refreshGeneralCalendar()"></div>
  </div>
</template>
<script>

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import listPlugin from '@fullcalendar/list'

  import { getDate, getHour } from '../../../utils/dateTimeExtensions'
  import { updateAppointmentConfirmation } from '../../../helpers/swalConfirmations'

  import calendarService from '../../../services/calendar'
  import staffService from '../../../services/staff'
  import branchRoomService from '../../../services/branchRoom'
  import commonService from '../../../services/common'
  import FastAppointment from '../../../components/appointment/FastAppointment.vue'
  import AppointmentDetails from '../../../components/appointment/AppointmentDetails'

  export default {
    components: {
      FullCalendar
    },
    props: {
      view: String
    },
    data() {
      return {
        TODAY: this.$moment(this.$parent.$parent.selectedDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        calendarPlugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin
        ],
        locale: this.$store.getters['Auth/currentLanguage'],
        defaultView: (this.view && this.view.length > 0) ? this.view : this.$store.getters['Calendar/generalCalendarType'],
        navlinks: true,
        slotDuration: '00:15:00',
        eventLimit: true,
        allDaySlot: false,
        firstDay: 1,
        weekStart: 1,
        longPressDelay: 300,
        minTime: this.$store.getters['Calendar/calendarStartTime'],
        maxTime: this.$store.getters['Calendar/calendarEndTime'],
        disableResizing: false,
        eventDurationEditable: this.$store.getters["Auth/checkPermission"]('appointment_edit'),
        eventStartEditable: this.$store.getters["Auth/checkPermission"]('appointment_edit'),
        selectable: this.$store.getters["Auth/checkPermission"]('appointment_new'),
        selectHelper: true,
        slotEventOverlap: false,
        handleWindowResize: true,
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true,
          hour12: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: this.$store.getters['Auth/currentLanguage'] ? false : true,
          hour12: this.$store.getters['Auth/currentLanguage'] ? false : true
        },
        buttonText: {
          today: this.$t("DateRangePicker.Today")
        },
        customButtons: {
          prev: { // this overrides the prev button
            text: "prev",
            click: () => {
              let calendarApi = this.$refs.generalCalendar.getApi();
              calendarApi.prev();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          next: { // this overrides the next button
            text: "next",
            click: () => {
              let calendarApi = this.$refs.generalCalendar.getApi();
              calendarApi.next();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          today: { // this overrides the today button
            text: this.$t("DateRangePicker.Today"),
            click: () => {
              let calendarApi = this.$refs.generalCalendar.getApi();
              calendarApi.today();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          }
        },
        selectedDate: '',
        calendarWeekends: true,

        staffId: '',
        roomId: '',
        appointmentStatusId: '',
        roomList: [],
        staffList: [],
        appointmentStatusList: [],
        scroll: 0,
        isListWeek: false
      };
    },
    methods: {
      getCalendarEvents(info, successCallback) {

        let pureStart = getDate(info.start);
        let pureEnd = getDate(info.end);
        this.$toastr.clear();

        this.$toastr.options = {
          "positionClass": "toast-top-center",
          "progressBar": true
        }
        this.$toastr.info(this.$t("Calendars.IncomingAppointments"));

        let roomId = this.roomId == null ? '' : this.roomId;
        let staffId = this.staffId == null ? '' : this.staffId;
        let appointmentStatusId = this.appointmentStatusId == null ? '' : this.appointmentStatusId;

        calendarService.getCalendarEvents(pureStart, pureEnd, roomId, staffId, appointmentStatusId, this.colorType)
          .then(response => {

            successCallback(response);

            if (this.isListWeek)
              document.querySelector('.fc-scroller').scrollTop = this.scroll;

          }).finally(() => {
            this.setPopoverTop();
            this.$toastr.clear();
            this.$toastr.options = null;

            if (this.staffList && this.staffList.length == 0)
              this.getStaffList();
            if (this.roomList && this.roomList.length == 0)
              this.getBranchRoomList();
            if (this.appointmentStatusList && this.appointmentStatusList.length == 0)
              this.getAppointmentStatusList();
          });
      },
      handleEventClick: function (info) {
        info.jsEvent.preventDefault();

        let $this = this;
        $this.$modal.show(
          AppointmentDetails,
          { info: info },
          {
            minWidth: 270,
            width: 450
          },
          {
            'before-close': () => {
              if ($this.$route.name.includes('calendar'))
                $this.$refs.generalCalendar.getApi().refetchEvents();
            }
          }
        )

        return false;

      },
      handleEventResize: function (info) {

        let minuteDelta = this.$moment.duration(info.endDelta.milliseconds).asMinutes();

        if (minuteDelta != 0) {
          let $this = this;

          updateAppointmentConfirmation(this.$t("AppointmentChangedAlert"),
            function () {
              calendarService.calendarResize(info.event.id, minuteDelta)
                .then(response => {
                  if (response.data == -1) {
                    $this.$toastr.error($this.$t("SaveFailedError"));
                    $this.$refs.generalCalendar.getApi().refetchEvents();
                  }
                  else {
                    $this.$refs.generalCalendar.getApi().refetchEvents();
                  }
                });
            }, function () {
              info.revert();
            });

        }
      },
      handleEventDrop: function (info) {

        let dayDelta = info.delta.days;
        let minuteDelta = this.$moment.duration(info.delta.milliseconds).asMinutes();

        if (dayDelta != 0 || minuteDelta != 0) {
          let $this = this;

          updateAppointmentConfirmation(this.$t("AppointmentChangedAlert"),
            function () {

              calendarService.calendarMove(info.event.id, dayDelta, minuteDelta)
                .then(response => {
                  if (response.data == -1) {
                    $this.$toastr.error($this.$t("SaveFailedError"));
                    $this.$refs.generalCalendar.getApi().refetchEvents();
                  }
                  else {
                    $this.$refs.generalCalendar.getApi().refetchEvents();
                  }
                });
            }, function () {
              info.revert();
            });

        }
      },
      handleEventRender: function (info) {
        let element = window.$(info.el);
        let tooltip = null;

        if (info.event.url != null) {
          if (info.event.url.indexOf("google") != -1) {
            element.draggable = false;
          }
        }

        if (!this.$store.getters['Settings/isMobileBrowser']) {
          element.attr(
            "data-tooltip",
            getHour(info.event.start) +
            " - " +
            getHour(info.event.end) +
            "<br />" +
            (info.event.extendedProps.customerName == null ||
              info.event.extendedProps.customerName.length == 0
              ? ""
              : info.event.extendedProps.customerName + "<br />") +
            (info.event.extendedProps.services == null ||
              info.event.extendedProps.services.length == 0
              ? ""
              : info.event.extendedProps.services + "<br />") +
            (info.event.extendedProps.staffName == null ||
              info.event.extendedProps.staffName.length == 0
              ? ""
              : info.event.extendedProps.staffName + "<br />") +
            (info.event.extendedProps.branchRoomName == null ||
              info.event.extendedProps.branchRoomName.length == 0
              ? ""
              : info.event.extendedProps.branchRoomName + "<br />") +
            (info.event.extendedProps.statusName == null ||
              info.event.extendedProps.statusName.length == 0
              ? ""
              : info.event.extendedProps.statusName + "<br />") +
            (info.event.extendedProps.notes != null &&
              info.event.extendedProps.notes.length > 0
              ? "<i class='fa fa-sticky-note'></i> " +
              info.event.extendedProps.notes
              : "")
          );

          element.on("mouseenter", function (event) {
            let tooltipContent = event.currentTarget.getAttribute("data-tooltip");

            tooltip = document.createElement("div");
            tooltip.classList.add("calendar-tooltip");
            tooltip.innerHTML = tooltipContent;

            // Yeni kod: �enti�i olu�turmak i�in bir span elementi ekle
            const notch = document.createElement("span");
            notch.classList.add("tooltip-notch");
            tooltip.appendChild(notch);

            document.body.appendChild(tooltip);

            // Yeni kod: Tooltip'in konumunu ayarla
            const elementRect = event.currentTarget.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const topOffset = elementRect.top + scrollTop - tooltipRect.height - 10;
            // Yeni kod: Elementin geni�li�ini al
            const elementWidth = elementRect.width;
            // Yeni kod: Tooltip'in geni�li�ini al
            const tooltipWidth = tooltipRect.width;
            // Yeni kod: Elementin ortalanm�� pozisyonunu hesapla
            const leftOffset = elementRect.left + (elementWidth / 2) - (tooltipWidth / 2);

            tooltip.style.top = `${topOffset}px`;
            tooltip.style.left = `${leftOffset}px`;
          });

          element.on("mouseleave", function () {
            if (tooltip) {
              tooltip.style.visibility = "hidden";
              tooltip.style.opacity = "0";
              document.body.removeChild(tooltip);
              tooltip = null;
            }
          });
        }
      },
      handleSelect: function (info) {

        if (this.$moment(info.start).hour() == 0)
          info.start = this.$moment(info.start).hour(9)

        if (this.$moment(info.end).hour() == 0)
          info.end = this.$moment(info.end).hour(10)


        info.date = getDate(info.start);
        info.start = getHour(info.start)
        info.end = getHour(info.end)

        let $this = this;
        $this.$modal.show(
          FastAppointment,
          { forCalendar: true, date: info.date, start: info.start, end: info.end, calendarType: 'general' },
          {
            height: 600
          },
          { 'before-close': () => { $this.$refs.generalCalendar.getApi().refetchEvents(); } }
        )
      },
      handleEventMouseLeave: function () {
        let elements = document.getElementsByClassName("calendar-tooltip");
        for (var i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      },
      refreshGeneralCalendar: function () {

        this.isListWeek = this.defaultView == 'listWeek' || (this.$refs.generalCalendar.getApi().view && this.$refs.generalCalendar.getApi().view.type == 'listWeek')

        if (this.isListWeek)
          this.scroll = document.querySelector('.fc-scroller').scrollTop;

        this.$refs.generalCalendar.getApi().refetchEvents()

      },
      getBranchRoomList: function () {
        branchRoomService.getBranchRoomList()
          .then(response => {
            this.roomList = response;
          });
      },
      getStaffList() {
        staffService.getStaffList()
          .then(response => {
            this.staffList = response;
          });
      },
      getAppointmentStatusList: function () {
        commonService.getAppointmentStatusList()
          .then(response => {
            this.appointmentStatusList = response;
          });
      },
      setPopoverTop() {
        const list = document.getElementsByClassName('fc-more');
        if (list && list != null && list.length > 0) {
          Array.from(list).forEach(el => {
            el.addEventListener('click', function () {
              let popover = document.getElementsByClassName('fc-popover');
              if (popover && popover.length > 0) {
                let top = popover[0].style.top;
                if (parseInt(top) < -100) {
                  popover[0].style.top = '-100px';
                }
              }
            });
          });
        }
      }
    },
    watch: {
      colorType: function () {
        this.$refs.generalCalendar.getApi().refetchEvents();
      },
      calendarType: function (val) {
        this.$refs.generalCalendar.getApi().changeView(val);
      },
      selectedDate: {
        handler: function (val) {
          if (val) {
            let calendarApi = this.$refs.generalCalendar.getApi();
            if (val != this.$moment(calendarApi.getDate()).format('DD.MM.YYYY')) {
              let date = this.$moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
              calendarApi.gotoDate(date);
            }
          }
        },
        immediate: true
      },
      roomId: function (val) {
        const $this = this;
        if (val !== undefined)
          setTimeout(function () { $this.$refs.generalCalendar.getApi().refetchEvents(); }, 50)
      },
      staffId: function (val) {
        const $this = this;
        if (val !== undefined)
          setTimeout(function () { $this.$refs.generalCalendar.getApi().refetchEvents(); }, 50)
      },
      appointmentStatusId: function (val) {
        const $this = this;
        if (val !== undefined)
          setTimeout(function () { $this.$refs.generalCalendar.getApi().refetchEvents(); }, 50)
      }
    },
    created: function () {
      this.$bus.$on('CalendarSelectedDate', (value) => {
        this.selectedDate = value;
      });
    },
    computed: {
      colorType: function () {
        return this.$store.getters['Calendar/calendarColorSource'];
      },
      calendarType: function () {
        return this.$store.getters['Calendar/generalCalendarType'];
      }
    },
    mounted: function () {
      this.setPopoverTop();
      this.$store.dispatch('Calendar/setLastSelectedCalendar', this.$route.name);
    }
  }
</script>


<style>
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~@fullcalendar/list/main.css';

  #generalCalendar .fc-content {
    color: white !important;
  }

  .fc-time-grid .fc-slats td {
    height: 30px !important;
  }

  .fc-day-grid-container {
    height: auto !important;
  }

  .fc-row.fc-rigid {
    height: 140px !important;
  }

  .calendar-tooltip {
    /* Tooltip stil ayarlar� */
    position: absolute;
    z-index: 9999;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #000;
    white-space: normal;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: opacity 0.3s;
    max-width: 150px;
    min-width: 50px;
    opacity: 1;
  }

  .tooltip-notch {
    /* �entik stil ayarlar� */
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 8px;
    border-color: #fff transparent transparent transparent;
  }
</style>
