<template>
  <b-container>
    <div>
      <header class="modal-header">
        <h5 class="modal-title">{{ showNewCustomerComponent ? $t('NewCustomer') : $t('NewAppointment') }}</h5>
        <button v-if="showNewCustomerComponent" class="btn btn-sm btn-secondary" @click="openNewCustomerComponent()">
          <i class="fa fa-arrow-left"></i>&nbsp;{{ $t('TurnBack') }}
        </button>
      </header>
      <div v-if="showNewCustomerComponent && !model.customerId" style="margin:5px 8px !important;">
        <fast-new-customer class="p-0 border-0 shadow-none" />
      </div>
      <div v-else class="modal-body">
        <b-row>
          <b-col md="4">
            <label class="form-control-label">{{ $t('Date') }}</label>
            <date-picker v-model="model.date"
                         :tag-name="'AppointmentDate'"
                         :is-required="true" />
          </b-col>
          <b-col md="4">
            <label class="form-control-label">{{ $t('StartTime') }}</label>
            <time-picker v-model="model.startTime" :is-required="true"></time-picker>
          </b-col>
          <b-col md="4">
            <label class="form-control-label">{{ $t('EndTime') }}</label>
            <time-picker v-model="model.endTime" :is-required="true"></time-picker>
          </b-col>
          <b-col md="12" class="text-right">
            <span class="text-danger error-mes" v-text="validationErrors.timeError" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <label class="form-control-label">{{ $t('Customer') }}</label>
            <auto-complete data-url="/Customer/AutoComplete"
                           data-hidden-name="CustomerSelectionComponent"
                           :data-id="model.customerId"
                           class="p-0" />
            <span class="text-danger error-mes"
                  v-text="validationErrors.customerId" />
          </b-col>
          <b-col md="4" v-if="!model.customerId">
            <label class="form-control-label"></label>
            <button class="btn btn-primary btn-block" style="margin-top:0.5rem;"
                    @click="openNewCustomerComponent()">
              <i class="fa fa-user-plus mr-2"></i> {{ $t('NewCustomer') }}
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <label class="form-control-label">{{ $t('BranchRoom') }}</label>
            <v-select transition="" v-model="model.roomId"
                      :clearable="false"
                      :reduce="s => s.id" label="name"
                      :options="roomList">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
            <span class="text-danger error-mes"
                  v-text="validationErrors.roomId" />
          </b-col>
          <b-col md="6">
            <label class="form-control-label">{{ $t('Staff') }}</label>
            <v-select transition="" v-model="model.staffId"
                      :clearable="false"
                      :reduce="s => s.id" label="name"
                      :options="staffList">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
            <span class="text-danger error-mes"
                  v-text="validationErrors.staffId" />
          </b-col>
          <b-col md="12" class="mt-2">
            <b-alert :show="roomAvailability.isExist" variant="warning">
              <div class="iq-alert-icon">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </div>
              <div class="iq-alert-text">{{ $t(roomAvailability.message) }}</div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-if="staffBranchCalendars.length>1">
          <b-form-group class="col-md-12" :label="$t('Calendar')" label-for="calendar">
            <v-select transition="" v-model="model.staffCalendarId"
                      id="calendar"
                      :disabled="isEdit"
                      :reduce="s => s.id" label="name"
                      :options="staffBranchCalendars"
                      :clearable="false"
                      required>
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col md="12">
            <label class="form-control-label">{{ $t('Service') }}</label>
            <v-select transition="" v-model="selectedService" id="fastappointment"
                      :reduce="s => s.id" label="name"
                      multiple
                      :searchable="true"
                      :filterBy="$root.filterOptions"
                      :options="serviceList">
              <template v-slot:option="option">
                <i :class="option.icon"></i>
                {{ option.name }}
              </template>
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
            <span class="text-danger error-mes"
                  v-text="validationErrors.services" />
          </b-col>
        </b-row>
        <b-row v-if="serviceTotalMinute >= 5">
          <b-col md="12 mb-2">
            <label class="text-primary mt-1">
              {{ $t('AppointmentComponent.TotalMinute', {minute: serviceTotalMinute }) }}
            </label>
            <b-form-group>
              <b-form-radio inline v-model="useTotalMinute" value="1">{{ $t('Yes') }}</b-form-radio>
              <b-form-radio inline v-model="useTotalMinute" value="0" checked>{{ $t('No') }}</b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <label class="form-control-label">{{ $t('AppointmentStatus') }}</label>
            <v-select transition="" v-model="model.statusId"
                      :reduce="s => s.id" label="name"
                      :options="appStatusList">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
          </b-col>
          <b-col md="6">
            <label class="form-control-label">{{ $t('AppointmentType') }}</label>
            <v-select transition="" v-model="model.typeId"
                      :reduce="s => s.id" label="name"
                      :options="appTypeList">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <label class="form-control-label">{{ $t('Notes') }}</label>
            <textarea class="form-control"
                      v-model="model.notes"
                      rows="2" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <modal-footer-button @submit="submitVueForm()" :is-vue-modal="true" :set-is-submitting="isSubmitting"></modal-footer-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>
<script>
  import DatePicker from '../../components/shared/DatePicker';
  import TimePicker from '../../components/shared/TimePicker';
  import AutoComplete from '../../components/shared/AutoComplete';
  import ModalFooterButton from '../../components/shared/ModalFooterButton';
  import FastNewCustomer from '../../components/customer/FastNewCustomer';

  import BranchRoomService from '../../services/branchRoom';
  import StaffService from '../../services/staff';
  import CommonService from '../../services/common';
  import EntityService from '../../services/entity';
  import AppointmentService from '../../services/appointment';

  export default {
    name: 'FastAppointment',
    props: {
      forCalendar: Boolean,
      calendarType: String,
      date: String,
      start: String,
      end: String,
      staffId: String,
      roomId: String
    },
    components: {
      DatePicker,
      TimePicker,
      AutoComplete,
      ModalFooterButton,
      FastNewCustomer
    },
    data() {
      return {
        model: {
          customerId: '',
          roomId: '',
          staffId: '',
          date: '',
          startTime: '',
          endTime: '',
          statusId: '',
          notes: '',
          appointmentService: [{ serviceId: null }],
          appointmentHelperStaff: [],
          staffCalendarId: null
        },
        validationErrors: {
          customerId: '',
          date: '',
          startTime: '',
          endTime: '',
          staffId: '',
          roomId: '',
          typeId: '',
          services: '',
          timeError: ''
        },
        roomAvailability: {},
        selectedService: [],
        selectedCustomerId: '',
        roomList: [],
        staffBranchCalendars: [],
        staffList: [],
        serviceList: [],
        appStatusList: [],
        appTypeList: [],
        flagForRequest: false,
        showNewCustomerComponent: false,
        isSubmitting: false,
        serviceTotalMinute: 0,
        useTotalMinute: 0
      }
    },
    created: function () {
      this.$bus.$on('CustomerSelectionComponent_onSelected', (value) => {
        this.selectedCustomerId = value;
        this.model.customerId = value;
        if (this.showNewCustomerComponent)
          this.showNewCustomerComponent = false;
      });
    },
    watch: {
      'model.customerId': function (val) {
        if (val) {
          this.validate();
        }
      },
      'model.date': function (val) {
        this.model.date = val;
        if (this.model.date && !this.flagForRequest) {
          this.getRoomAvailability();
          this.validate();
        }
      },
      'model.roomId': function (val) {
        this.model.roomId = val;
        if (this.model.roomId && !this.flagForRequest) {
          this.getRoomAvailability();
          this.validate();
        }
      },
      'model.startTime': function (val) {
        this.model.startTime = val;
        if (this.model.startTime && (this.model.startTime < this.model.endTime) && !this.flagForRequest) {
          this.getRoomAvailability();
          this.validate();
        }
      },
      'model.endTime': function (val) {
        this.model.endTime = val;
        if (this.model.startTime && (this.model.endTime > this.model.startTime) && !this.flagForRequest) {
          this.getRoomAvailability();
          this.validate();
        }
      },
      'model.staffId': function (val) {
        this.model.staffId = val;

        if (this.model.staffId) {
          this.getStaffBranchCalendar(this.model.staffId);

          this.selectedService = [];
          this.model.appointmentService = [];
          this.getServiceList(this.model.staffId);
          if (!this.flagForRequest) {
            this.getRoomAvailability();
            this.validate();
          }
        }
      },
      'model.staffCalendarId': function (val) {
        if (this.model.staffId && val) {
          this.selectedService = [];
          this.model.appointmentService = [];
          this.getServiceList(this.model.staffId);
        }
      },
      selectedService: {
        handler: function (list) {
          let $this = this;
          $this.model.appointmentService = [];
          list.forEach(z => {
            $this.model.appointmentService.push({ serviceId: z });
          });
          this.calculateServiceTotalMinute();
          this.validate();
        },
        deep: true
      },
      useTotalMinute: function (val) {
        if (val == 1) {
          this.model.endTime = this.$moment(this.model.startTime, "HH:mm").add(this.serviceTotalMinute, 'minutes').format("HH:mm");

          if (this.model.endTime > this.$store.getters['Calendar/calendarEndTime'])
            this.model.endTime = this.$store.getters['Calendar/calendarEndTime'];
        }
        else {
          this.model.endTime = this.end;
        }
      }
    },
    computed: {
      isServiceSelected: function () {
        return this.model.appointmentService && this.model.appointmentService.filter(el => el.serviceId && el.serviceId.length > 0).length > 0;
      }
    },
    methods: {
      submitVueForm() {
        let isValid = this.validate();

        if (isValid) {
          this.isSubmitting = true;
          let url = "/appointment/new";
          if (this.appointmentId && this.appointmentId.length > 0)
            url = "/appointment/edit"

          AppointmentService.submitAppointmentForm(url, this.model)
            .then(response => {
              this.isSubmitting = false;
              if (response && response.success) {
                this.$toastr.success(this.$t("Success"));
                this.closeModal();
              }
              else {
                if (response && response.message)
                  this.$toastr.error(this.$t(response.message));
                else
                  this.$toastr.error(this.$t("Error"));
              }
            }).finally(() => { this.isSubmitting = false; });
        }
      },
      getStaffBranchCalendar(staffId) {
        CommonService.getStaffBranchCalendar(staffId)
          .then(response => {
            this.staffBranchCalendars = response;

            if (this.staffBranchCalendars.length > 0 && !this.isEdit) {
              this.staffBranchCalendars.forEach(calendar => {
                if (calendar.isSelected) {
                  this.model.staffCalendarId = calendar.id;
                }
              });
            }
          });
      },
      validate() {
        let isValid = true;

        if (!this.model.customerId) {
          isValid = false;
          this.validationErrors.customerId = this.$t('ValidationMessage.CannotBeEmptyCustomer');
        }
        else { this.validationErrors.customerId = '' }

        if (!this.isServiceSelected) {
          isValid = false;
          this.validationErrors.services = this.$t('ValidationMessage.CannotBeEmpty');
        }
        else { this.validationErrors.services = '' }

        if (!this.model.staffId) {
          isValid = false;
          this.validationErrors.staffId = this.$t('ValidationMessage.CannotBeEmpty');
        }
        else { this.validationErrors.staffId = '' }

        if (!this.model.roomId) {
          isValid = false;
          this.validationErrors.roomId = this.$t('ValidationMessage.CannotBeEmpty');
        }
        else { this.validationErrors.roomId = '' }

        if (!this.model.date || !this.model.startTime || !this.model.endTime)
          isValid = false;

        if (this.model.startTime >= this.model.endTime) {
          isValid = false;
          this.validationErrors.timeError = this.$t('ValidationMessage.TimeError');
        }
        else { this.validationErrors.timeError = '' }

        return isValid;
      },
      getBranchRoomList() {
        BranchRoomService.getBranchRoomList(this.model && this.model.id && this.model.roomId ? { id: this.model.roomId } : {})
          .then(response => { this.roomList = response });
      },
      getStaffList() {
        StaffService.getStaffList((this.model && this.model.id && this.model.staffId) ? { params: { id: this.model.staffId, forSaleRep: 0, onlyShowInCalendar: 1 } } : { params: { id: null, forSaleRep: 0, onlyShowInCalendar: 1 } })
          .then(response => { this.staffList = response });
      },
      getAppointmentStatusList() {
        CommonService.getAppointmentStatusList().then(response => {
          this.appStatusList = response;

          if (!this.appointmentID) {
            let defaultStatus = this.appStatusList.find(z => z.isDefault);
            if (defaultStatus)
              this.model.statusId = defaultStatus.id;
          }
        });
      },
      getAppointmentTypeList() {
        CommonService.getAppointmentTypeList()
          .then(response => { this.appTypeList = response });
      },
      getServiceList(staffId) {
        if (staffId && staffId.length > 0) {
          EntityService.getServiceList(this.model.appointmentService.serviceId, staffId, this.model.staffCalendarId)
            .then(response => { this.serviceList = response; });
        }
      },
      getRoomAvailability() {
        AppointmentService.getRoomAvailabilityForManualSelected(
          {
            roomId: this.model.roomId,
            staffId: this.model.staffId,
            date: this.model.date,
            startTime: this.model.startTime,
            endTime: this.model.endTime
          }
        )
          .then(response => {
            this.roomAvailability = response;
            this.flagForRequest = false;
          });
      },
      updateForCalendar() {

        if (this.staffId && this.staffId.length > 0) { this.model.staffId = this.staffId; }
        if (this.roomId && this.roomId.length > 0) { this.model.roomId = this.roomId; }

        this.model.date = this.date;
        this.model.startTime = this.start;
        this.model.endTime = this.end;
        this.getRoomAvailability();

      },
      closeModal() {
        this.$modal.hide(
          'vue-modal'
        );
      },
      openNewCustomerComponent() {
        if (this.showNewCustomerComponent)
          this.showNewCustomerComponent = false;
        else
          this.showNewCustomerComponent = true;
      },
      calculateServiceTotalMinute() {
        this.serviceTotalMinute = 0;
        this.model.appointmentService.forEach(el => {
          if (el.serviceId != null && el.serviceId.length > 0) {
            this.getServiceMinute(el.serviceId);
          }
        })
      },
      getServiceMinute(serviceId) {
        AppointmentService.getServiceMinute(serviceId)
          .then(response => {
            if (response >= 5) {
              this.serviceTotalMinute += response;
            }
          });
      }
    },
    mounted: function () {
      this.getBranchRoomList();
      this.getStaffList();
      this.getAppointmentStatusList();
      this.getAppointmentTypeList();

      if (this.forCalendar)
        this.flagForRequest = true;
      this.updateForCalendar();
    }
  }
</script>

<style>
  #fastappointment .vs__selected {
    display: flex;
    align-items: center;
    border: none;
    background-color: rgb(76, 176, 197);
    border-radius: 4px;
    color: white;
    margin: 4px 2px 0;
    padding: 0.3em;
    z-index: 0;
    font-size: 0.95rem;
  }

  .vm--modal {
    overflow: scroll !important;
  }
</style>
