<template>
  <div>
    <div class="form-group row" style="display:flex;">
      <div class="col-md-4">
        <label class="form-control-label">{{ $t('AppointmentStatus') }}</label>
        <v-select transition="" v-model="appointmentStatusId"
                  :reduce="s => s.id" label="name"
                  :options="appointmentStatusList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </div>
    </div>
    <FullCalendar ref="timeGridCalendar"
                  id="timeGridCalendar"
                  :defaultView="defaultView"
                  :plugins="plugins"
                  :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
                  :locale="locale"
                  :header=" {
                                right: 'prev,next,today',
                                left: 'title',
                                center: ''
                            }"
                  :buttonText="buttonText"
                  :custom-buttons="customButtons"
                  :allDaySlot="allDaySlot"
                  :minTime="minTime"
                  :maxTime="maxTime"
                  :disableResizing="disableResizing"
                  :selectable="selectable"
                  :defaultDate="TODAY"
                  :selectHelper="selectHelper"
                  :eventLimit="eventLimit"
                  :slotDuration="slotDuration"
                  :longPressDelay="longPressDelay"
                  :eventDurationEditable="eventDurationEditable"
                  :eventStartEditable="eventStartEditable"
                  :titleFormat="titleFormat"
                  :droppable="droppable"
                  :slotLabelFormat="slotLabelFormat"
                  :slotEventOverlap="slotEventOverlap"
                  :eventResourceEditable="eventResourceEditable"
                  :handleWindowResize="handleWindowResize"
                  :eventTimeFormat="eventTimeFormat"
                  :resourceLabelText="resourceLabel"
                  :resources="gridGetResources"
                  :events="gridGetEvents"
                  @eventRender="gridEventRender"
                  @eventClick="gridEventClick"
                  @eventResize="gridEventResize"
                  @eventDrop="gridEventDrop"
                  @select="gridSelect"
                  @eventMouseLeave="gridEventMouseLeave" />
    <div id="timeGridCalendar_refresher" @click="refreshGridCalendar()" />
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue';
  import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
  import interactionPlugin from '@fullcalendar/interaction';

  import { getDate, getDateWithHour, getHour } from '../../../utils/dateTimeExtensions'
  import { updateAppointmentConfirmation } from '../../../helpers/swalConfirmations'

  import calendarService from '../../../services/calendar'
  import commonService from '../../../services/common'
  import FastAppointment from '../../../components/appointment/FastAppointment'
  import AppointmentDetails from '../../../components/appointment/AppointmentDetails'

  export default {
    components: {
      FullCalendar
    },
    props: {
      mode: String
    },
    data() {
      return {
        TODAY: this.$moment(this.$parent.$parent.selectedDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        plugins: [resourceTimeGridPlugin, interactionPlugin],
        locale: this.$store.getters['Auth/currentLanguage'],
        defaultView: 'resourceTimeGridDay',
        allDaySlot: false,
        longPressDelay: 300,
        minTime: this.$store.getters['Calendar/calendarStartTime'],
        maxTime: this.$store.getters['Calendar/calendarEndTime'],
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true,
          hour12: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true
        },
        buttonText: {
          today: this.$t("DateRangePicker.Today"),
          month: this.$t("Month"),
          week: this.$t("Week"),
          day: this.$t("Day"),
        },
        titleFormat: {
          year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
        },
        customButtons: {
          prev: { // this overrides the prev button
            text: "prev",
            click: () => {
              let calendarApi = this.$refs.timeGridCalendar.getApi();
              calendarApi.prev();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          next: { // this overrides the next button
            text: "next",
            click: () => {
              let calendarApi = this.$refs.timeGridCalendar.getApi();
              calendarApi.next();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          },
          today: { // this overrides the today button
            text: this.$t("DateRangePicker.Today"),
            click: () => {
              let calendarApi = this.$refs.timeGridCalendar.getApi();
              calendarApi.today();
              let date = calendarApi.getDate();
              this.$parent.$parent.selectedDate = this.$moment(date).format('DD.MM.YYYY');
            }
          }
        },
        eventLimit: true,
        slotDuration: '00:15:00',
        slotEventOverlap: false,
        selectable: true,
        selectHelper: true,
        disableResizing: false,
        eventDurationEditable: this.$store.getters['Auth/checkPermission']('appointment_edit'),
        eventStartEditable: this.$store.getters['Auth/checkPermission']('appointment_edit'),
        eventResourceEditable: this.$store.getters['Auth/checkPermission']('appointment_new'),
        droppable: true,
        handleWindowResize: true,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true,
          hour12: this.$store.getters['Auth/currentLanguage'] == 'tr' ? false : true
        },
        selectedDate: '',
        dragDropAppointmentID: null,

        appointmentStatusId: '',
        appointmentStatusList: []

      };
    },
    methods: {
      gridGetResources: function (info, successCallback) {
        if (this.mode === 's' || this.mode === 'S') {

          calendarService.getCalendarEventStaffList()
            .then(response => {
              successCallback(response);
            });
        }
        else {

          calendarService.getCalendarEventRoomList()
            .then(response => {
              successCallback(response);
            });
        }
      },
      gridGetEvents: function (info, successCallback) {

        let pureStart = getDateWithHour(info.start);
        let pureEnd = getDateWithHour(info.end);
        this.$toastr.clear();

        this.$toastr.options = {
          "positionClass": "toast-top-center",
          "progressBar": true
        }
        this.$toastr.info(this.$t("Calendars.IncomingAppointments"));

        let appointmentStatusId = this.appointmentStatusId == null ? '' : this.appointmentStatusId;

        calendarService.getCalendarEventsForColumnCalendar(pureStart, pureEnd, this.mode, appointmentStatusId, this.colorType)
          .then(response => {
            successCallback(response);
          }).finally(() => {
            this.$toastr.clear();
            this.$toastr.options = null;

            if (this.appointmentStatusList && this.appointmentStatusList.length == 0)
              this.getAppointmentStatusList();
          });

      },
      gridEventRender: function (info) {
        let element = window.$(info.el);

        let pureStart = getHour(info.event.start);
        let pureEnd = getHour(info.event.end);
        let statusIcon = info.event.extendedProps.statusIcon;
        let statusState = info.event.extendedProps.statusState;
        let hasDebt = info.event.extendedProps.hasCustomerDebt;
        let isHelperStaff = info.event.extendedProps.isHelperStaff;
        let isOnlineMeeting = info.event.extendedProps.isOnlineMeeting;

        element.draggable = true;

        let tooltip = null;

        if (!this.$store.getters['Settings/isMobileBrowser']) {
          element.attr(
            "data-tooltip",
            getHour(info.event.start) +
            " - " +
            getHour(info.event.end) +
            "<br />" +
            (info.event.extendedProps.customerName == null ||
              info.event.extendedProps.customerName.length == 0
              ? ""
              : info.event.extendedProps.customerName + "<br />") +
            (info.event.extendedProps.services == null ||
              info.event.extendedProps.services.length == 0
              ? ""
              : info.event.extendedProps.services + "<br />") +
            (info.event.extendedProps.staffName == null ||
              info.event.extendedProps.staffName.length == 0
              ? ""
              : info.event.extendedProps.staffName + "<br />") +
            (info.event.extendedProps.branchRoomName == null ||
              info.event.extendedProps.branchRoomName.length == 0
              ? ""
              : info.event.extendedProps.branchRoomName + "<br />") +
            (info.event.extendedProps.statusName == null ||
              info.event.extendedProps.statusName.length == 0
              ? ""
              : info.event.extendedProps.statusName + "<br />") +
            (info.event.extendedProps.notes != null &&
              info.event.extendedProps.notes.length > 0
              ? "<i class='fa fa-sticky-note'></i> " +
              info.event.extendedProps.notes
              : "")
          );

          element.on("mouseenter", function (event) {
            let tooltipContent = event.currentTarget.getAttribute("data-tooltip");

            tooltip = document.createElement("div");
            tooltip.classList.add("calendar-tooltip");
            tooltip.innerHTML = tooltipContent;

            // Yeni kod: �enti�i olu�turmak i�in bir span elementi ekle
            const notch = document.createElement("span");
            notch.classList.add("tooltip-notch");
            tooltip.appendChild(notch);

            document.body.appendChild(tooltip);

            // Yeni kod: Tooltip'in konumunu ayarla
            const elementRect = event.currentTarget.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const topOffset = elementRect.top + scrollTop - tooltipRect.height - 10;
            // Yeni kod: Elementin geni�li�ini al
            const elementWidth = elementRect.width;
            // Yeni kod: Tooltip'in geni�li�ini al
            const tooltipWidth = tooltipRect.width;
            // Yeni kod: Elementin ortalanm�� pozisyonunu hesapla
            const leftOffset = elementRect.left + (elementWidth / 2) - (tooltipWidth / 2);

            tooltip.style.top = `${topOffset}px`;
            tooltip.style.left = `${leftOffset}px`;
          });

          element.on("mouseleave", function () {
            if (tooltip) {
              tooltip.style.visibility = "hidden";
              tooltip.style.opacity = "0";
              document.body.removeChild(tooltip);
              tooltip = null;
            }
          });
        }

        if (hasDebt) {
          element.find('.fc-title').addClass("dotted");
        }

        if (isHelperStaff) {
          element.find('.fc-title').addClass("striped2");
        }

        if (isOnlineMeeting) {
          element.find('.fc-title').addClass("gradient");
        }

        if (statusIcon && statusIcon.length > 0 && statusIcon != 'fa fa-clock-o') {
          element.find('.fc-time').html(`<span><i class='${statusIcon}'></i>&nbsp; ${pureStart} - ${pureEnd}</span>`);

        }

        if (statusState == '-') {
          element.find('.fc-title').removeClass("dotted");
          element.find('.fc-title').removeClass("striped2");
          element.find('.fc-title').removeClass("gradient");
          element.find('.fc-title').addClass("striped");
        }
      },
      gridEventClick: function (info) {
        info.jsEvent.preventDefault();

        let $this = this;
        $this.$modal.show(
          AppointmentDetails,
          { info: info },
          {
            minWidth: 270,
            width: 450
          },
          {
            'before-close': () => {
              if ($this.$route.name.includes('calendar'))
                $this.$refs.timeGridCalendar.getApi().refetchEvents();
            }
          }
        )

        return false;
      },
      gridEventResize: function (info) {

        let url = "";

        let pureStart = getDateWithHour(info.event.start);
        let pureEnd = getDateWithHour(info.event.end);

        if (this.mode == "s" || this.mode == "S") {
          if (info.event.extendedProps.isHelperStaff == null || info.event.extendedProps.isHelperStaff == 0) {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&staffId=" + info.event._def.resourceIds[0];
          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd;
          }

        }
        else {
          url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
            + "&branchRoomId=" + info.event._def.resourceIds[0];
        }

        let $this = this;
        updateAppointmentConfirmation($this.$t("AppointmentChangedAlert"),
          function () {
            calendarService.updateAppointmentStartAndEndTime(url)
              .then(response => {
                if (response == -1) {
                  $this.$toastr.error($this.$t("SaveFailedError"));
                  $this.$refs.timeGridCalendar.getApi().refetchEvents();
                }
                else if (response == -2) {
                  $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                }
                else {
                  $this.$toastr.success($this.$t("Success"));
                  $this.$refs.timeGridCalendar.getApi().refetchEvents();
                }
              });
          }, function () {
            info.revert();
          });
      },
      gridEventDrop: function (info) {
        let url = "";

        let pureStart = getDateWithHour(info.event.start);
        let pureEnd = getDateWithHour(info.event.end);

        if (this.mode == "s" || this.mode == "S") {
          if (info.event.extendedProps.isHelperStaff == null || info.event.extendedProps.isHelperStaff == 0) {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&staffId=" + info.event._def.resourceIds[0];
          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd;
          }
        }
        else {
          url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + info.event.id + "&startDate=" + pureStart + "&endDate=" + pureEnd
            + "&branchRoomId=" + info.event._def.resourceIds[0];
        }

        let $this = this;
        updateAppointmentConfirmation(this.$t("AppointmentChangedAlert"),
          function () {
            calendarService.updateAppointmentStartAndEndTime(url)
              .then(response => {
                if (response == -1) {
                  $this.$toastr.error($this.$t("SaveFailedError"));
                  $this.$refs.timeGridCalendar.getApi().refetchEvents();
                }
                else if (response == -2) {
                  $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                }
                else {
                  $this.$toastr.success($this.$t("Success"));
                  $this.$refs.timeGridCalendar.getApi().refetchEvents();
                }
              });
          }, function () {
            info.revert();
          });
      },
      gridSelect: function (info) {
        if (this.dragDropAppointmentID && this.dragDropAppointmentID.length > 0) {

          let url = "";

          let pureStart = getDateWithHour(info.start);
          let pureEnd = getDateWithHour(info.end);

          if (this.mode == "s" || this.mode == "S") {

            if (info.resource.id != null || info.resource.id.length > 0) {
              url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd
                + "&staffId=" + info.resource.id;
            }
            else {
              url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd;
            }

          }
          else {
            url = "/Calendar/UpdateAppointmentStartAndEndTime?appointmentId=" + this.dragDropAppointmentID + "&startDate=" + pureStart + "&endDate=" + pureEnd
              + "&branchRoomId=" + info.resource.id;
          }

          let $this = this;
          updateAppointmentConfirmation(this.$t("AppointmentChangedAlert"),
            function () {
              calendarService.updateAppointmentStartAndEndTime(url)
                .then(response => {
                  if (response == -1) {
                    $this.$toastr.error($this.$t("SaveFailedError"));
                    $this.$refs.timeGridCalendar.getApi().refetchEvents();
                  }
                  else if (response == -2) {
                    $this.$toastr.info($this.$t("PleaseLoginAndTryAgain"));
                  }
                  else {
                    $this.$toastr.success($this.$t("Success"));
                    $this.dragDropAppointmentID = null;
                    $this.$refs.timeGridCalendar.getApi().refetchEvents();
                  }
                });
            }, function () {
              info.revert();
              $this.dragDropAppointmentID = null;
              $this.$toastr.error($this.$t("MoveCanceled"));
            });

        }
        else {

          if (this.mode == "s" || this.mode == "S") {
            info.staffId = info.resource.id;
          }
          else {
            info.branchRoomId = info.resource.id;
          }

          info.date = getDate(info.start);
          info.start = getHour(info.start)
          info.end = getHour(info.end)

          let $this = this;
          if ($this.mode == "s" || $this.mode == "S") {

            $this.$modal.show(
              FastAppointment,
              { forCalendar: true, date: info.date, start: info.start, end: info.end, staffId: info.staffId, calendarType: 'staff' },
              {
                height: 600
              },
              { 'before-close': () => { $this.$refs.timeGridCalendar.getApi().refetchEvents(); } }
            )
          }
          else {

            $this.$modal.show(
              FastAppointment,
              { forCalendar: true, date: info.date, start: info.start, end: info.end, roomId: info.branchRoomId, calendarType: 'room' },
              {
                height: 600
              },
              { 'before-close': () => { $this.$refs.timeGridCalendar.getApi().refetchEvents(); } }
            )
          }
        }
      },
      gridEventMouseLeave: function () {
        let elements = document.getElementsByClassName("calendar-tooltip");
        for (var i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      },
      initSelectableHours: function () {

        let $this = this;
        let list = document.querySelectorAll('[data-time]')

        let clock1 = '';
        let clock2 = '';
        list.forEach(el => {
          el.style.cursor = 'pointer';

          el.addEventListener('click', function () {
            el.style.color = '#1dc9b7';

            if (clock1 != "" && clock2 != "") {
              document.querySelector(`[data-time='${clock1}']`).style.color = '#646c9a';
              document.querySelector(`[data-time='${clock2}']`).style.color = '#646c9a';
              clock1 = "";
              clock2 = "";

            }

            if (clock1 == "") {
              clock1 = el.dataset.time;
            }
            else if (clock2 == "") {
              clock2 = el.dataset.time;
            }

            if (clock1 == clock2) {
              return false;
            }

            let start = clock1.substring(0, 5);
            let end = clock2.substring(0, 5);
            let date = $this.selectedDate ? $this.selectedDate : getDate($this.TODAY);

            if (start.length >= 4 && end.length >= 4 && date) {

              $this.$modal.show(
                FastAppointment,
                { forCalendar: true, date: date, start: start, end: end },
                {
                  height: 600
                },
                {
                  'before-open': () => {

                    document.querySelector(`[data-time='${clock1}']`).style.color = '#646c9a';
                    document.querySelector(`[data-time='${clock2}']`).style.color = '#646c9a';
                  },
                  'before-close': () => {

                    $this.$refs.timeGridCalendar.getApi().refetchEvents();
                  }
                }
              )
            }

          });
        });
      },
      refreshGridCalendar: function () {
        this.$refs.timeGridCalendar.getApi().refetchEvents()
      },
      getAppointmentStatusList: function () {
        commonService.getAppointmentStatusList()
          .then(response => {
            this.appointmentStatusList = response;
          });
      }
    },
    watch: {
      //isVueModalOpen: function (val) {
      //  if (!val) {
      //    this.$refs.timeGridCalendar.getApi().refetchEvents();
      //  }
      //},
      colorType: function () {
        this.$refs.timeGridCalendar.getApi().refetchEvents();
      },
      appointmentStatusId: function (val) {
        const $this = this;
        if (val !== undefined) {
          setTimeout(function () { $this.$refs.timeGridCalendar.getApi().refetchEvents(); }, 50)
        }
      },
      selectedDate: {
        handler: function (val) {
          if (val) {
            let calendarApi = this.$refs.timeGridCalendar.getApi();
            if (val != this.$moment(calendarApi.getDate()).format('DD.MM.YYYY')) {
              let date = this.$moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
              calendarApi.gotoDate(date);
            }

            this.initSelectableHours();
          }
        },
        immediate: true
      },
    },
    computed: {
      resourceLabel: function () {
        return this.mode === 's' || this.mode === 'S' ? this.$t("Staff") : this.$t("Room");
      },
      //isVueModalOpen: function () {
      //  return this.$store.state.showModal;
      //},
      colorType: function () {
        return this.$store.getters['Calendar/calendarColorSource'];
      }
    },
    created: function () {
      this.$bus.$on('CalendarDragDrop', (value) => {
        this.dragDropAppointmentID = value;
      });
      this.$bus.$on('CalendarSelectedDate', (value) => {
        this.selectedDate = value;
      });
    },
    mounted: function () {
      this.$store.dispatch('Calendar/setLastSelectedCalendar', this.$route.name);

      this.initSelectableHours();
    }
  }
</script>

<style scoped>
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/timegrid/main.css';

  .calendar-tooltip {
    /* Tooltip stil ayarlar� */
    position: absolute;
    z-index: 9999;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #000;
    white-space: normal;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: opacity 0.3s;
    max-width: 150px;
    min-width: 50px;
    opacity: 1;
  }

  .tooltip-notch {
    /* �entik stil ayarlar� */
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 8px;
    border-color: #fff transparent transparent transparent;
  }
</style>
